<template>
  <v-tooltip
    v-model="show"
    bottom
    :max-width="390"
    color="white"
    :nudge-right="160"
    :nudge-top="-10"
    content-class="tooltip-content"
    :open-on-click="true"
    :open-on-hover="false"
    :open-on-focus="false"
    :disabled="disableTooltip"
  >
    <div class="tooltip-content__triangle"></div>
    <template #activator="{ on, attrs }">
      <div
        :class="[
          `time__slot mb-3 ${daySlotClass}`,
          { faded: (limitReached && !picked) || missedSlot },
        ]"
        v-bind="attrs"
        v-on="on"
        @click="pickSlot"
      >
        {{ daySlotTime || "–" }}
      </div>
    </template>
    <span>{{ toolTipCaption }}</span>
  </v-tooltip>
</template>

<script>
import { get } from "vuex-pathify"
import { sync } from "vuex-pathify"

export default {
  name: "DaySlot",
  props: {
    daySlot: {
      type: Object,
      required: true,
      default: () => ({
        time: "–",
        available: false,
      }),
    },
    limitReached: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
  }),

  computed: {
    tooltipState: sync("lessons/tooltipState"),
    lessonsLimit: sync("lessons/limit"),
    gracePeriod: sync("lessons/gracePeriod"),

    toolTipCaption() {
      if (this.slotAlreadyScheduled) return this.$t("schedule.slotAlreadyScheduled")
      else if (this.missedSlot) return this.$tc("schedule.missedSlot", this.gracePeriod)
      else return this.$t("schedule.limitReached", { limit: this.lessonsLimit })
    },

    disableTooltip() {
      if ((this.slotAlreadyScheduled || this.missedSlot) && this.tooltipState === this.daySlot.id)
        return false
      else return !this.limitReached || this.picked || this.tooltipState != this.daySlot.id
    },

    slotAlreadyScheduled() {
      return !!this.daySlot?.scheduled
    },

    missedSlot() {
      return !!this.daySlot?.missed
    },

    daySlotClass() {
      const picked = this.picked ? "picked" : ""
      if (this.daySlot.available && !this.slotAlreadyScheduled) {
        return `time__slot--available ${picked}`
      } else if (this.slotAlreadyScheduled) {
        return "time__slot--available time__slot--scheduled"
      } else {
        return "time__slot--unavailable"
      }
    },
    daySlotTime() {
      return this.daySlot?.time ?? "–"
    },
    selected: get("lessons/selected"),
    picked() {
      return !!this.selected[this.daySlot.id]
    },
  },
  methods: {
    pickSlot() {
      this.$emit("pick")
      if (
        (this.limitReached || this.slotAlreadyScheduled || this.missedSlot) &&
        this.tooltipState != this.daySlot.id &&
        !this.show
      ) {
        this.show = true
        this.tooltipState = this.daySlot.id

        setTimeout(() => {
          this.show = false
        }, 3500)
      } else {
        this.show = false
        this.tooltipState = ""
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip-content {
  opacity: 1 !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  color: #304154;
  padding: 26px 22px;
  z-index: 15;

  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: -13px;
    left: 23px;
    border-width: 0 11px 13px 11px;
    border-color: transparent transparent #ffffff transparent;
    filter: drop-shadow(1px 0px 0px rgba(0, 1, 1, 4.5));
  }
}

.faded {
  opacity: 0.5;
}

.time {
  &__slot {
    border-radius: 0.375rem;
    font-size: 0.75rem;
    text-align: center;
    font-weight: 600;

    &.picked {
      background-color: #f5f5f5;
    }
    &--available {
      cursor: pointer;
      background-color: #d0d6e9;
    }
    &--unavailable {
      text-decoration: line-through;
    }
    &--scheduled {
      opacity: 0.5;
      border: 3px solid var(--v-grayscale-darken3);
    }
  }
}
</style>
