import { api } from "@/api"
import {
  transformDataKeys,
  transformToFormDataSerialize,
  capitalizeFirstLetter,
} from "@/utils/profileUtils"
import Vue from "vue"
import _cloneDeep from "lodash.clonedeep"

const setFormData = async (payload, regFn) => {
  const transformedKeys = transformDataKeys(payload)
  const formData = transformToFormDataSerialize(transformedKeys)
  return await regFn(formData)
}

const initialStateProfile = {
  id: 0,
  email: "",
  role: "student",
  registration_complete: false,
  first_name: "",
  last_name: "",
  date_of_birth: "",
  phone_number: "",
  image_url: null,
  video_url: null,
  taught_languages: [],
  teaching_languages: [],
  language_specializations: [],
  description: "",
  average_rating: 0,
  ratings: [],
  social_links: [],
  tutorial_finished: false,
  registration_completed: false,
  has_cyclical_timetable: false,
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
  sms_notifications_enabled: false,
  email_notifications_enabled: false,
  commercial_sms_consent: false,
  commercial_email_consent: false,
  active_until: "",
  company_name: "",
  onboarding_completed_at: null,
  about: null,
  only_group_lessons: undefined,
}
const initialStateFile = {
  name: "",
  file: null,
}
const photoMaxSize = 5 * 1024 * 1024
const videoMaxSize = 100 * 1024 * 1024

export default {
  namespaced: true,
  state: () => ({
    id: null,
    locale: "",
    profile: {
      ...initialStateProfile,
    },
    agreements: {},
    picture: {
      ...initialStateFile,
    },
    imageUrl: "",
    video: {
      ...initialStateFile,
    },
    videoUrl: "",
    registrationImagePreview: "",
  }),
  getters: {
    getProfile: (state) => ({
      ...state.profile,
      imageUrl: state.imageUrl,
      videoUrl: state.videoUrl,
      id: state.id,
    }),
    getName: (state) => state.profile.first_name,
    isWarned: (state) => !!state.profile.warned_at,
    getLastName: (state) => state.profile.last_name,
    getPhone: (state) => state.profile.phone_number,
    getPassword: (state) => state.profile.password,
    getEmail: (state) => state.profile.email,
    getLocale: (state) => state.profile.locale,
    getDateOfBirth: (state) => state.profile.date_of_birth,
    getPasswordConfirm: (state) => state.profile.passwordConfirmation,
    getAgreements: (state) => state.agreements,
    getPicture: (state) => state.picture.file,
    getPictureName: (state) => state.picture.name,
    getVideo: (state) => state.video.file,
    getVideoName: (state) => state.video.name,
    getCompanyName: (state) => state.profile.company_name,
    comparePasswords: (state) =>
      !!state.profile.password &&
      !!state.profile.passwordConfirmation &&
      state.profile.password === state.profile.passwordConfirmation,
    pictureUploaded: (state) => !!state.picture?.file,
    videoUploaded: (state) => !!state.video?.file,
    agreementsChecked: (state, getters, rootState) =>
      Object.values(state.agreements)?.every((agr) => {
        if (!agr.req) return true
        else if (agr.checked) return true
        else if (agr.type === "student" && rootState.user.role === "teacher") return true
        else if (agr.type === "teacher" && rootState.user.role === "student") return true
        else return false
      }),
    getDescription: (state) => state.profile.description,
    getTeachingLanguages: (state) => state.profile.teaching_languages,
    getTaughtLanguages: (state) => state.profile.taught_languages,
    isTaughtLanguagesFilled: (state) => {
      const { taught_languages } = state.profile
      const [firstLang] = taught_languages
      if (!firstLang) return

      return taught_languages.every((language) => {
        return Object.values(language).every(
          (value) => (isNaN(value) ? value.length && value.every(Boolean) : value > 0), //checks if value is a number or an array of numbers
        )
      })
    },
    isSocialLinksFilled: (state) => {
      const { social_links } = state.profile
      const [firstSocial] = social_links
      return !!firstSocial
    },
    isPictureUploaded: (state) => !!state.profile?.image_url,
    isVideoUploaded: (state) => !!state.profile?.video_url,
    isPersonalDataFilled: (state, _, rootState) =>
      !!state.profile.first_name &&
      !!state.profile.last_name &&
      state.profile.phone_number.length > 5 &&
      !!state.profile.date_of_birth &&
      (!!state.profile.email || rootState["user/role"] === "student"),
    isSkillDataFilled: (state, getters) => {
      return (
        !!state.profile?.description &&
        !!state.profile?.teaching_languages?.length &&
        !!getters.isTaughtLanguagesFilled
      )
    },
    getSocialLinks: (state) => state.profile.social_links,
    isActive: (state) => state.profile.registration_completed,
    getMaxSize: () => ({
      picture: photoMaxSize,
      video: videoMaxSize,
    }),
    getUserActiveUntil: (state) => state.profile.active_until,
  },
  mutations: {
    SET_NAME(state, payload) {
      Vue.set(state.profile, "first_name", capitalizeFirstLetter(payload))
    },
    SET_LAST_NAME(state, payload) {
      Vue.set(state.profile, "last_name", capitalizeFirstLetter(payload))
    },
    SET_PHONE(state, payload) {
      Vue.set(state.profile, "phone_number", payload)
    },
    SET_EMAIL(state, payload) {
      this.commit("SET_ERROR_FROM_SERVER", "")
      Vue.set(state.profile, "email", payload)
    },
    SET_PASSWORD(state, payload) {
      Vue.set(state.profile, "password", payload)
    },
    SET_PASSWORD_CONFIRM(state, payload) {
      Vue.set(state.profile, "passwordConfirmation", payload)
    },
    SET_AGREEMENTS(state, payload) {
      state.agreements = payload
    },
    TOGGLE_AGREEMENT(state, { flag, key }) {
      if (!state.agreements?.[key]) return

      state.agreements[key].checked = flag
      Vue.set(state.agreements[key], "checked", flag)
    },
    SET_PICTURE(state, { name, file }) {
      Vue.set(state.picture, "file", file)
      Vue.set(state.picture, "name", name)
    },
    SET_VIDEO(state, { name, file }) {
      Vue.set(state.video, "file", file)
      Vue.set(state.video, "name", name)
    },
    SET_DATE_OF_BIRTH(state, value) {
      Vue.set(state.profile, "date_of_birth", value)
    },
    SET_PROFILE_DATA(state, payload) {
      //teacher doesn't have warned_at field, so we need to reset it
      Vue.set(state.profile, "warned_at", null)
      Object.keys(payload).forEach((key) => {
        if (key === "registration_completed") {
          localStorage.setItem("registration_completed", payload[key])
        }
        Vue.set(state.profile, key, payload[key])
      })
      state.locale = payload.locale ?? "en"
      state.imageUrl = payload.image_url ?? ""
      state.videoUrl = payload.video_url ?? ""
      state.id = payload.id || state.id
    },
    SET_TEACHER_DATA_FIELD(state, { key, value }) {
      Vue.set(state.profile, key, value)
    },
    SET_TEACHING_LANGUAGES(state, lang) {
      Vue.set(state.profile, "teaching_languages", lang)
    },
    SET_TAUGHT_LANGUAGES(state, payload) {
      Vue.set(state.profile, "taught_languages", payload)
    },
    RESET_PROFILE_STATE(state) {
      Object.keys(initialStateProfile).forEach((key) => {
        Vue.set(state.profile, key, initialStateProfile[key])
      })
      Object.keys(initialStateFile).forEach((key) => {
        Vue.set(state.picture, key, initialStateFile[key])
        Vue.set(state.video, key, initialStateFile[key])
        state.imageUrl = ""
        state.videoUrl = ""
        state.id = ""
        state.agreements = {}
      })
    },
    SET_REGISTRATION_IMAGE_PREVIEW(state, payload) {
      state.registrationImagePreview = payload
    },
  },
  actions: {
    async updateProfile({ state, commit }, payload) {
      const isNotificationsUpdate = Object.keys(payload).some((item) =>
        item.includes("notifications"),
      )
      const updated = await setFormData(payload, api.updateProfile)

      if (isNotificationsUpdate) {
        // do not update profile image if notifications are updated
        updated.image_url = state.imageUrl
      }

      if (updated) {
        commit("SET_PROFILE_DATA", updated)
      }
      return updated
    },

    async updateLanguages({ dispatch }, payload) {
      await api.updateProfile(payload)
      dispatch("setProfileData")
    },
    async registerTeacher({ commit }, payload) {
      const registered = await setFormData(payload, api.registerTeacher)

      if (registered) {
        commit("SET_PROFILE_DATA", registered)
        this.commit("user/RESET_USER_STATE")
        this.commit("profile/RESET_PROFILE_STATE")
      }
      return registered
    },
    async setProfileData({ commit }) {
      try {
        const res = await api.getProfileData()
        localStorage.setItem("group_lessons", res.only_group_lessons)
        commit("SET_PROFILE_DATA", res)
      } catch (e) {
        console.error(e)
      }
    },
    updateSkillData({ commit }, payload) {
      commit("SET_TEACHER_DATA_FIELD", payload)
    },
    fetchAgreements({ commit }) {
      api.fetchAgreements().then((res) => {
        const agreements = _cloneDeep(res)
        commit("SET_AGREEMENTS", agreements)
      })
    },
  },
}
