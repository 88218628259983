<template>
  <div>
    <SharedDialog class="dialog" hide-confirm-button>
      <template #title>
        <p class="dialog__title">{{ $t("warnDialog.title") }}!</p>
      </template>
      <template #content>
        <p
          class="dialog__description"
          v-html="$t('warnDialog.text', { hours: $tc('warnDialog.hours', cancellationEarliness) })"
        />
      </template>
    </SharedDialog>
  </div>
</template>

<script>
import { api } from "@/api"

export default {
  data() {
    return {
      cancellationEarliness: "",
    }
  },
  async created() {
    const { cancellation_earliness } = await api.getSettings()
    this.cancellationEarliness = cancellation_earliness
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}
</style>
