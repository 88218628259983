<template>
  <v-app-bar
    v-click-outside="closeMenu"
    flat
    app
    class="nav px-16 w-100"
    :class="{ mobile, active }"
    height="unset"
    min-height="80"
  >
    <div class="nav__mobile">
      <v-btn
        text
        icon
        class="nav__mobile-btn"
        :class="{ 'nav__mobile-btn--active': active }"
        @click="toggleMobileNav"
      >
        <v-icon>menu</v-icon>
      </v-btn>
    </div>
    <transition class="nav__transition" name="links" tag="div">
      <div v-show="!mobile || active" key="navmenu" class="nav__links" :class="{ active }">
        <v-toolbar-title class="nav__logo d-flex flex-row">
          <router-link to="/" class="d-flex align-center">
            <img src="../../assets/logo.svg" style="width: 127px" />
          </router-link>
        </v-toolbar-title>
        <router-link
          v-for="link in routeLinks"
          :key="link.name"
          :to="link.to"
          active-class="nav__link--active"
          class="nav__link"
        >
          <div v-if="link.visible">
            <img v-if="link.imagePath" :src="link.imagePath" :alt="link.name" />
            <span v-else>{{ link.name }}</span>
          </div>
        </router-link>
        <v-divider v-if="mobile" class="w-100 my-2"></v-divider>
        <BaseButton v-if="mobile" clear link plain @click.prevent="logOutAndRedirect">{{
          $t("message.logut")
        }}</BaseButton>
      </div>
    </transition>
    <div v-if="profileId" class="nav__controls">
      <LanguageDropDownMenu @change-language="changeLanguage"></LanguageDropDownMenu>
      <div class="nav__profile">
        <router-link :to="{ name: 'profile' }">
          <v-img
            max-width="51"
            :lazy-src="require('@/assets/profile.svg')"
            :src="imageUrl"
            class="nav__image"
          />
        </router-link>
        <ProfileDropDownMenu
          v-if="!mobile"
          class="nav__menu"
          :items="profileMenuitems"
        ></ProfileDropDownMenu>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapActions, mapMutations } from "vuex"
import { get, sync } from "vuex-pathify"

import ProfileDropDownMenu from "@/components/DropDownMenus/ProfileDropDownMenu.vue"
import LanguageDropDownMenu from "@/components/DropDownMenus/LanguageDropDownMenu.vue"
import tableMixin from "@/mixins/tableMixin"
import screenWidth from "@/mixins/screenWidth"
import notyMixin from "@/mixins/notyMixin"

export default {
  name: "Navigation",

  components: {
    LanguageDropDownMenu,
    ProfileDropDownMenu,
  },
  mixins: [tableMixin, screenWidth, notyMixin],

  computed: {
    profile: get("profile/profile"),
    profileId: get("profile/id"),
    active: sync("layout/activeMenu"),
    role: sync("user/role"),
    auth: sync("user/auth"),
    storedImage: sync("profile/imageUrl"),
    locale: get("profile/locale"),
    companyName: get("profile/profile.company_name"),
    profile: get("profile/profile"),

    routeLinks() {
      let links = []
      if (this.role === "student") {
        links = [
          {
            name: this.messages.myLessons,
            to: { name: "my-lessons" },
            visible: true,
          },
          {
            name: this.messages.scheduledLesson,
            to: { name: "set-lessons" },
            visible: !this.profile.only_group_lessons,
          },
          {
            name: this.messages.itTeachers,
            to: { name: "it-teachers" },
            imagePath: require("@/assets/InpostLogo.svg"),
            visible: this.companyName?.toLowerCase().includes("inpost"),
          },
        ]
      }

      if (this.role === "teacher") {
        links = [
          {
            name: this.messages.myLessons,
            to: { name: "my-lessons" },
            visible: true,
          },
        ]
      }

      return links
    },
    routePath() {
      return this.$route.path
    },
    messages() {
      return {
        myLessons: this.$t("message.myLessons"),
        scheduledLesson: this.$t("message.scheduleLesson"),
        itTeachers: this.$t("message.itTeachers"),
      }
    },
    profileMenuitems() {
      return [
        {
          title: this.$t("message.myProfile"),
          handler: this.goToProfile,
        },
        {
          title: this.$t("message.logut"),
          handler: this.logOutAndRedirect,
        },
      ]
    },
    imageUrl() {
      return this.storedImage || require("@/assets/profile.svg")
    },
    mobile() {
      return this.screenWidth <= 1100
    },
  },
  watch: {
    $route() {
      this.setActiveMenu(false)
    },
  },
  async created() {
    try {
      await this.getProfileData()
      if (this.$i18n) {
        this.$i18n.locale = this.locale
      }
      if (this.$vuetify?.lang) {
        this.$vuetify.lang.current = this.locale
      }
      if (this.profile.warned_at) {
        this.setDialog({ active: true, dialogType: "BanWarnDialog" })
      }
      localStorage.setItem("locale", this.locale)
    } catch (err) {
      console.err(err)
    }
  },
  methods: {
    ...mapActions({
      logOut: "user/logOut",
      getProfileData: "profile/setProfileData",
      updateProfile: "profile/updateProfile",
      setDialog: "layout/setDialog",
    }),
    ...mapMutations({
      setLocale: "SET_LOCALE",
      setActiveMenu: "layout/SET_MENU_ACTIVE",
    }),
    toggleMobileNav() {
      this.setActiveMenu(!this.active)
    },
    goToProfile() {
      this.$router.push({ name: "profile" }).catch((error) => {
        console.error(error)
      })
    },
    logOutAndRedirect() {
      const path = `/login/${this.role}`
      this.logOut()
      this.$router.push({ path }).catch((error) => {
        console.error(error)
      })
    },
    async changeLanguage(lang) {
      const locale = lang.toLowerCase()
      try {
        await this.updateProfile({ locale })
        localStorage.setItem("locale", locale)
        this.$router.go()
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.changeLocale") })
        console.error(err)
      }
    },
    closeMenu() {
      this.setActiveMenu(false)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/sass/rwd_breakpoints.scss";
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--v-grayscale-lighten3) !important;
  margin: 0 auto;
  z-index: 110 !important;

  @media (max-width: $bp-t) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;

    @media (min-width: ($bp-t + 1)) {
      width: 1440px;
    }
    @media (max-width: $bp-t) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &__mobile {
    display: none;

    @media (max-width: $bp-t) {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      order: 0;
    }
  }
  &__logo {
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    @media (min-width: ($bp-t + 1)) {
      margin-right: 4rem;
    }
  }
  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: $bp-t) {
      flex-basis: 50%;
      order: 1;
    }
  }
  &__profile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__links {
    display: flex;
    @media (max-width: $bp-t) {
      width: 100%;
      padding: 1rem 0;
      flex-direction: column;
      align-items: center;
      order: 2;
      text-transform: uppercase;
    }
  }
  &__link {
    padding: 0 2rem;
    color: var(--v-black-base);
    text-decoration: none;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }
    &--active {
      text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
    }

    @media (max-width: $bp-t) {
      padding: 1rem 0;
    }
  }
  &__image {
    width: 51px;
    height: 51px;
    border-radius: 100%;
    margin: 0 1rem 0 1rem;
    object-fit: cover;
  }
  &__name {
    letter-spacing: 1px;
  }
  &__transition {
    @media (max-width: $bp-t) {
      width: 100%;
      flex-direction: column;
      order: 2;
    }
  }
}
::v-deep {
  .v-toolbar__content {
    height: 100%;
    min-width: 330px;
    flex-wrap: wrap;
    @media (max-width: $bp-t) {
      padding-top: 1rem !important;
    }
  }
}
.links-enter-active,
.links-leave-active {
  @media (max-width: $bp-t) {
    transition: all 0.2s;
  }
}
.links-enter,
.links-leave-to {
  @media (max-width: $bp-t) {
    opacity: 0;
  }
}
</style>
