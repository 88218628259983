<template>
  <SharedDialog class="dialog" width="600" :hide-confirm-button="true"
    ><template #title>
      <p class="title">{{ messages.scheduleLesson }}</p>
    </template>
    <template #content>
      <WorkSchedule
        :teacher-id="teacherId"
        :language="dialogData.data.language"
        :level="dialogData.data.level"
        style="margin: 0 0 0 0; padding: 0"
        class="mt-4"
      ></WorkSchedule>
    </template>
  </SharedDialog>
</template>

<script>
import WorkSchedule from "@/components/WorkSchedule/WorkSchedule.vue"
import { mapGetters, mapActions } from "vuex"

export default {
  components: { WorkSchedule },

  computed: {
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),

    teacherId() {
      return new String(this.dialogData.data.teacher.id)
    },

    messages() {
      return {
        scheduleLesson: this.$t("message.scheduleLesson"),
        next: this.$t("message.next"),
      }
    },
  },
  created() {
    this.setTeacherInfo(this.teacherId)
  },
  methods: {
    ...mapActions({
      setTeacherInfo: "teacher/setTeacherInfo",
    }),
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: var(--v-grayscale-darken3);
}

::v-deep .v-btn__content {
  color: white;
}
</style>
