import Vue from "vue"
import VueI18n from "vue-i18n"
import en from "./en"
import pl from "./pl"
import enValidation from "vee-validate/dist/locale/en.json"
import plValidate from "vee-validate/dist/locale/pl.json"
Vue.use(VueI18n)

export const messages = {
  en: {
    ...en,
    validation: {
      ...enValidation.messages,
      ...{
        min: "Min {length} symbols",
        minDigits: "Min {digits} digits",
        uniqueMail: "This email is already in use",
      },
    },
  },
  pl: {
    ...pl,
    validation: {
      ...plValidate.messages,
      ...{
        min: "Minimum {length} znaków",
        minDigits: "Minimum {digits} cyfr",
        uniqueMail: "Email jest już zajęty",
      },
    },
  },
}

const i18n = new VueI18n({
  locale: localStorage.getItem("locale")?.toLowerCase() || "pl",
  fallbackLocale: "en",
  messages,
  pluralizationRules: {
    pl: (choice) => {
      if (choice === 0 || choice === 1) return choice
      const teen = choice > 10 && choice < 20
      const specialCase = choice % 10 >= 2 && choice % 10 <= 4
      if (!teen && specialCase) return 2
      return 3
    },
  },
})

export default i18n
